@import url('https://fonts.googleapis.com/css?family=Roboto|Montserrat|Lato|Open+Sans&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .MuiInputBase-root, .MuiTypography-root {
    color: var(--color-body-invert);
  }
  .MuiInputLabel-root {
    color: var(--color-body-invert-dim);
  }
  // overflow-y: overlay;
}

body.oak-dialog-open {
  // height: 100vh;
  overflow-y: hidden;
  padding-right: var(--scrollbar-width);
}

.body {
  font-family: 'Lato', sans-serif;
  background-color: var(--color-body);
  color: var(--color-body-invert);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// 34ba9b
// f68c70

:root {
  --color-pick: #4B5DB3;
  // --color-pick: #7A93DC;
  --height-nav: 50px;

  --color-themecolor-1: #69A7BF;
  --color-themecolor-2: #99587B;
  --color-themecolor-3: #A66C26;
  --color-themecolor-4: #37AE82;

  .themecolor1 {
    --color-dark-primary: #91CCD9;
    --color-dark-secondary: #69A7BF;
    --color-dark-tertiary:#376B8C;
    --color-dark-tertiary-trans:rgba(55,107,140,0.2);
    --color-light-primary: #376B8C;
    --color-light-secondary: #69A7BF;
    --color-light-tertiary:#91CCD9;
    --color-light-tertiary-trans:rgba(145,204,217,0.2);
  }

  .themecolor2 {
    --color-dark-primary: #BA7799;
    --color-dark-secondary: #99587B;
    --color-dark-tertiary:#753959;
    --color-dark-tertiary-trans:rgba(117, 57, 89, 0.2);
    --color-light-primary: #753959;
    --color-light-secondary: #99587B;
    --color-light-tertiary: #BA7799;
    --color-light-tertiary-trans:rgba(186, 119, 153, 0.2);
  }

  .themecolor3 {
    --color-dark-primary: #D99E30;
    --color-dark-secondary: #A66C26;
    --color-dark-tertiary:#734822;
    --color-dark-tertiary-trans:rgba(115, 72, 34, 0.2);
    --color-light-primary: #734822;
    --color-light-secondary: #A66C26;
    --color-light-tertiary:#D99E30;
    --color-light-tertiary-trans:rgba(217, 158, 48, 0.2);
  }

  .themecolor4 {
    --color-dark-primary: #82D9B0;
    --color-dark-secondary: #37AE82;
    --color-dark-tertiary:#1E8861;
    --color-dark-tertiary-trans:rgba(30, 136, 97, 0.1);
    --color-light-primary: #1E8861;
    --color-light-secondary: #37AE82;
    --color-light-tertiary:#82D9B0;
    --color-light-tertiary-trans:rgba(130, 217, 176, 0.1);
  }

  .theme_dark {

    --color-body: #222831;
    --color-body-dim: #363B43;
    --color-body-invert: #fcfcfc;
    --color-body-invert-dim: #A1A1A1;
    --color-primary: var(--color-dark-primary);
    --color-secondary: var(--color-dark-secondary);
    --color-tertiary:var(--color-dark-tertiary);
    --color-tertiary-trans:var(--color-dark-tertiary-trans);
    --color-default: rgba(217,217,217,1);
    --color-selected: rgba(217,217,217,1);
    --color-hover: rgba(217,217,217,0.2);
    --color-white: white;
    --color-black: black;
    --color-success: #437A34;
    --color-warning: #49A078;
    --color-failure: #8C030E;

    --color-sidebar-header: #444444;
    --color-sidebar-header-active: var(--color-tertiary-trans);
    --color-sidebar-element: #2d2d2d;
    --color-sidebar-fg-hover: rgba(255, 255, 255, 1);
    --color-sidebar-fg: rgba(255, 255, 255, 0.7);
  
    --color-nav: #2a3036;
    --color-nav-shadow: rgba(252,252,252,.15);
    --color-nav-text: var(--color-white);
    --color-nav-hover: rgba(239, 91, 91, 0.1);
    --color-nav-active: var(--color-primary);
    --color-nav-slider: var(--color-body);
    --color-navitem-mobile: var(--color-white);


    --color-btn-primary-bg: var(--color-primary);
    --color-btn-primary-fg: var(--color-body-invert);
    --color-btn-primary-fg-hover: black;
    --color-btn-secondary-bg: var(--color-secondary);
    --color-btn-secondary-fg: var(--color-body-invert);
    --color-btn-secondary-fg-hover: black;
    --color-btn-tertiary-bg: var(--color-tertiary);
    --color-btn-tertiary-fg: var(--color-body-invert);
    --color-btn-tertiary-fg-hover: black;
    --color-btn-default-bg: var(--color-default);
    --color-btn-default-fg: var(--color-body-invert);
    --color-btn-default-fg-hover: black;
    
    --color-btn-action-danger-bg: #DC758F;

    --color-dialog: var(--color-nav);

    --color-tag: #404040;
    --color-box-shadow: rgba(255,255,255,0.2);
  }
  
  .theme_light {
    --color-body: #fcfcfc;
    --color-body-dim: #E6E6E6;
    --color-body-invert: #252525;
    --color-body-invert-dim: #6E6E6E;
    --color-primary: var(--color-light-primary);
    --color-secondary: var(--color-light-secondary);
    --color-tertiary:var(--color-light-tertiary);
    --color-tertiary-trans:var(--color-light-tertiary-trans);
    --color-default: rgba(64,64,64,1);
    --color-selected: rgba(64,64,64,1);
    --color-hover: rgba(64,64,64,0.2);
    --color-white: white;
    --color-black: black;
    --color-success: #437A34;
    --color-warning: #49A078;
    --color-failure: #8C030E;
  
    --color-sidebar-header: #E6E6E6;
    --color-sidebar-header-active: var(--color-tertiary-trans);
    --color-sidebar-element: var(--color-body);
    --color-sidebar-fg-hover: rgba(0, 0, 0, 1);
    --color-sidebar-fg: rgba(0, 0, 0, 0.7);

    --color-nav: #ececec;
    --color-nav-shadow: rgba(0,0,0,.15);
    --color-nav-text: var(--color-black);
    --color-nav-hover: rgba(239, 91, 91, 0.1);
    --color-nav-active: var(--color-primary);
    --color-nav-slider: var(--color-body);
    --color-navitem-mobile: var(--color-black);

    --color-btn-primary-bg: var(--color-primary);
    --color-btn-primary-fg: var(--color-body-invert);
    --color-btn-primary-fg-hover: white;
    --color-btn-secondary-bg: var(--color-secondary);
    --color-btn-secondary-fg: var(--color-body-invert);
    --color-btn-secondary-fg-hover: white;
    --color-btn-tertiary-bg: var(--color-tertiary);
    --color-btn-tertiary-fg: var(--color-body-invert);
    --color-btn-tertiary-fg-hover: white;
    --color-btn-default-bg: var(--color-default);
    --color-btn-default-fg: var(--color-body-invert);
    --color-btn-default-fg-hover: white;

    --color-btn-action-danger-bg: #B33951;

    --color-dialog: var(--color-body);

    --color-tag: #E6E6E6;
    --color-box-shadow: rgba(0,0,0,0.30);
    
  }
}



// /* Color Theme Swatches in Hex */
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-1-hex { color: #0F2F59; }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-2-hex { color: #1F4C73; }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-3-hex { color: #3A6D8C; }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-4-hex { color: #75AABF; }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-5-hex { color: #9CD3D9; }

// /* Color Theme Swatches in RGBA */
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-1-rgba { color: rgba(15, 47, 89, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-2-rgba { color: rgba(30, 75, 114, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-3-rgba { color: rgba(57, 108, 140, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-4-rgba { color: rgba(116, 170, 191, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-5-rgba { color: rgba(156, 210, 216, 1); }

// /* Color Theme Swatches in HSLA */
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-1-hsla { color: hsla(213, 70, 20, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-2-hsla { color: hsla(208, 57, 28, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-3-hsla { color: hsla(202, 41, 38, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-4-hsla { color: hsla(196, 36, 60, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-5-hsla { color: hsla(185, 44, 73, 1); }